<template>
  <div class="py-4">
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">
      <router-link to="/dashboard"> Dashboard </router-link>
      <span class="text-sm">/ {{ business.business_name }} </span>
    </h1>
    <div class="flex flex-row justify-evenly mt-10">
      <div class="border-2 border-gray-200 w-full mr-2">
        <div class="py-4 bg-gray-200 text-center text-3xl">
          {{ getStringMonth[currentMonth] }}
        </div>
        <div class="text-center py-7">
          <div class="text-1xl">Total Messages Sent</div>
          <div class="text-2xl text-color font-semibold mt-2">
            {{ business.messages_sent || "0" }}
          </div>

          <div class="text-1xl mt-4">Total Messages Received</div>
          <div class="text-2xl text-blue-600 font-semibold mt-2 mb-4">
            {{ business.messages_received || "0" }}
          </div>

          <router-link
            class="bg-blue-400 p-2 px-8 text-blue-800 font-semibold rounded"
            :to="`/dashboard/business/${getBusinessId()}/history`"
          >
            History
          </router-link>
        </div>
      </div>
      <div class="w-full text-center border-2 border-gray-200 ml-2 pt-8">
        <div class="text-2xl">
          {{
            getTotalBalance(
              business.message_allotment,
              business.messages_sent,
              business.messages_received
            ).title
          }}
        </div>
        <div
          class=" font-semibold text-8xl opacity-90 py-9"
          :class="
            getTotalBalance(
              business.message_allotment,
              business.messages_sent,
              business.messages_received
            ).textcolor
          "
        >
          {{
            getTotalBalance(
              business.message_allotment,
              business.messages_sent,
              business.messages_received
            ).value
          }}
        </div>
        <div class="text-sm">
          Any texts over the monthly allotment will be added to the monthly
          billing
        </div>
      </div>
    </div>

    <div class="font-semibold inline-flex w-full mt-4">
      <div class="w-1/4">Location Name</div>
      <div class="w-1/4 text-center">Phone Number</div>
      <div class="w-1/4 text-center">Messages Sent</div>
      <div class="w-1/4 text-center">Messages Received</div>
    </div>

    <div
      class="inline-flex w-full my-2"
      v-for="location in locations"
      :key="location.id"
    >
      <div class="w-1/4 ">
        <router-link
          :to="`/dashboard/business/${getBusinessId()}/location/${location.id}`"
        >
          <a class="text-blue-400 capitalize cursor-pointer">
            {{ location.location_name }}
          </a>
        </router-link>
      </div>
      <div class="w-1/4 text-center">{{ location.twilio_number }}</div>
      <div class="w-1/4 text-center">
        {{ location.location_messages_sent || "0" }}
      </div>
      <div class="w-1/4 text-center">
        {{ location.location_messages_received || "0" }}
      </div>
    </div>
  </div>
</template>
<script>
const firebase = require("../../firebaseConfig");

export default {
  name: "DashboardBusinessAdmin",
  async mounted() {
    this.user = await this.getUserInfo();
    this.getBusiness();
    this.getLocations();
  },
  data: () => ({
    currentMonth: new Date().getMonth(),
    business: {},
    locations: [],
    business_id: 0,
    user: {},
    messagesCount: 0,
    getStringMonth: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  methods: {
    getUserInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                uid: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    getBusinessId() {
      return this.$route.params.business_id
        ? this.$route.params.business_id
        : this.user.business_id;
    },
    getBusiness() {
      firebase.db
        .collection("businesses")
        .doc(this.getBusinessId())
        .onSnapshot((doc) => {
          this.business = {
            id: doc.id,
            ...doc.data(),
          };
        });
    },
    getLocations() {
      firebase.db
        .collection("businesses")
        .doc(this.getBusinessId())
        .collection("locations")
        .onSnapshot((docs) => {
          this.locations = [];
          docs.forEach((doc) => {
            this.locations.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },
    getTotalBalance(allotment, sent, received) {
      return allotment - (sent + received) > 0
        ? {
            title: "Remaining Balance",
            value: allotment - (sent + received),
            textcolor: "text-green-600",
          }
        : {
            title: "Total Messages Sent",
            value: Math.abs(allotment - (sent + received)),
            textcolor: "text-red-600",
          };
    },
  },
};
</script>
<style scoped>
.text-color {
  color: #f78201;
}
</style>
