import { render } from "./DashboardBusinessAdmin.vue?vue&type=template&id=5a48d846&scoped=true"
import script from "./DashboardBusinessAdmin.vue?vue&type=script&lang=js"
export * from "./DashboardBusinessAdmin.vue?vue&type=script&lang=js"

import "./DashboardBusinessAdmin.vue?vue&type=style&index=0&id=5a48d846&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-5a48d846"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5a48d846"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5a48d846', script)) {
    api.reload('5a48d846', script)
  }
  
  module.hot.accept("./DashboardBusinessAdmin.vue?vue&type=template&id=5a48d846&scoped=true", () => {
    api.rerender('5a48d846', render)
  })

}

script.__file = "src/components/dashboard/DashboardBusinessAdmin.vue"

export default script